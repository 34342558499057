import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';
import './PostDetails.css';

const socket = io('https://travel-class.com');

const PostDetails = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [message, setMessage] = useState('');
  const [nickname, setNickname] = useState(localStorage.getItem('nickname') || '');
  const [messages, setMessages] = useState([]);
  const [lastMessageTime, setLastMessageTime] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch(`https://travel-class.com/api/posts/${id}`);
        const data = await response.json();
        setPost(data);
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };

    const fetchMessages = async () => {
      try {
        const response = await fetch(`https://travel-class.com/api/posts/${id}/messages`);
        const data = await response.json();
        setMessages(data);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchPost();
    fetchMessages();

    socket.on('receiveMessage', (data) => {
      setMessages((prevMessages) => [...prevMessages, data]);
    });

    return () => {
      socket.off('receiveMessage');
    };
  }, [id]);

  const handleSendMessage = () => {
    const now = Date.now();
    if (message.trim() === '' || nickname.trim() === '') return;
    if (lastMessageTime && now - lastMessageTime < 5000) {
      alert('You are sending messages too quickly. Please wait a moment.');
      return;
    }

    const timestamp = new Date().toLocaleString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
    const data = { postId: id, message, nickname, timestamp };
    socket.emit('sendMessage', data);
    setMessage('');
    setLastMessageTime(now);
    localStorage.setItem('nickname', nickname);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleTextareaResize = (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = e.target.scrollHeight + 'px';
  };

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div className="post-details-container">
      <h2>{post.title}</h2>
      <p>{post.description}</p>
      <p><strong>Date:</strong> {post.date}</p>
      <p><strong>Location:</strong> {post.location}</p>
      <p><strong>Nickname:</strong> {post.nickname}</p>
      <p><strong>Country:</strong> {post.country}</p>

      <h3>Chat</h3>
      <div className="chat-container">
        {messages.map((msg, index) => (
          <div key={index} className="chat-message">
            <div className="message-header">
              <strong>{msg.nickname}</strong> <span className="timestamp">{msg.timestamp}</span>
            </div>
            <div className="message-body">
              {msg.message}
            </div>
          </div>
        ))}
      </div>
      <div className="input-container">
        <input
          type="text"
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
          className="chat-input"
          placeholder="Your nickname"
          maxLength="20"
        />
        <textarea
          value={message}
          onChange={handleMessageChange}
          onInput={handleTextareaResize}
          className="chat-textarea"
          placeholder="Type a message"
          maxLength="200"
        />
        <button 
          onClick={handleSendMessage} 
          className="send-button"
          disabled={message.trim() === '' || nickname.trim() === ''}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default PostDetails;
