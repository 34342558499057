import React, { useState, useEffect } from 'react';
import './CreatePost.css';
import { useNavigate } from 'react-router-dom';

const CreatePost = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [location, setLocation] = useState('');
  const [country, setCountry] = useState('');
  const [nickname, setNickname] = useState('');
  const [prefectures, setPrefectures] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    // JSONファイルから県リストを取得する
    fetch('/prefectures.json')
      .then(response => response.json())
      .then(data => setPrefectures(data))
      .catch(error => console.error('Error fetching prefectures:', error));
  }, []);

  const handleCreatePost = async () => {
    if (!title || !description || !date || !location || !country || !nickname) {
      alert('All fields are required!');
      return;
    }

    const postData = { title, description, date, location, country, nickname };
    console.log('Sending post data:', postData); // ここでログを出力

    const response = await fetch('https://travel-class.com/api/posts', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData)
    });
    const data = await response.json();
    console.log(data);
    setTitle('');
    setDescription('');
    setDate('');
    setLocation('');
    setCountry('');
    setNickname('');
    navigate(`/posts/${data._id}`);
  };

  return (
    <div className="create-post-container">
      <h2>Create New Post</h2>
      <select
        value={location}
        onChange={(e) => setLocation(e.target.value)}
        className="input-field"
      >
        <option value="">Select Location</option>
        {Object.entries(prefectures).map(([name, value]) => (
          <option key={value} value={value}>
            {name} ({value})
          </option>
        ))}
      </select>
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Title"
        className="input-field"
        maxLength="50"
      />
      <textarea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Description"
        className="textarea-field"
        maxLength="500"
      />
      <input
        type="date"
        value={date}
        onChange={(e) => setDate(e.target.value)}
        className="input-field"
      />
      <input
        type="text"
        value={nickname}
        onChange={(e) => setNickname(e.target.value)}
        placeholder="Nickname"
        className="input-field"
        maxLength="20"
      />
      <input
        type="text"
        value={country}
        onChange={(e) => setCountry(e.target.value)}
        placeholder="Country"
        className="input-field"
        maxLength="30"
      />
      <button onClick={handleCreatePost} className="create-button">Create Post</button>
    </div>
  );
};

export default CreatePost;
