import React, { useEffect } from 'react';
import './App.css';
import Home from './components/Home';
import PostDetails from './components/PostDetails';
import CreatePost from './components/CreatePost';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import icon from './assets/icon.png';
import socket from './socket';

function App() {
  useEffect(() => {
    // クリーンアップ: コンポーネントがアンマウントされるときにソケットを切断する
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <div className="header-content">
            <Link to="/">
              <img src={icon} alt="Traveler's Class in Japan" className="header-icon" />
            </Link>
            <h1>Traveler's Class in Japan</h1>
          </div>
          <nav>
            <Link to="/">Home</Link>
            <Link to="/create-post">Create Post</Link>
          </nav>
        </header>
        <main>
          <section className="intro">
            <p>
              We are a platform connecting travelers to Japan with individuals eager to learn languages.
              Our system is designed to be mutually beneficial, with each party bearing their own costs
              (such as transportation, meals, and admission fees). We aim to foster a community where personal
              profit is not the primary focus.
            </p>
            <p>
              私たちは、日本を訪れる旅行者と、言語を学びたいと考える人々をつなぐプラットフォームです。
              私たちのシステムは、お互いに利益をもたらすよう設計されており、交通費、食事代、入場料などの費用は各自が負担します。
              私たちは、個人的な利益が主な目的ではないコミュニティの形成を目指しています。
            </p>
          </section>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/posts/:id" element={<PostDetails />} />
            <Route path="/create-post" element={<CreatePost />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
