import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import socket from '../socket';

const Home = () => {
  const [posts, setPosts] = useState([]);
  const [search, setSearch] = useState('');
  const [date, setDate] = useState('');
  const [location, setLocation] = useState('');
  const [prefectures, setPrefectures] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 10;

  useEffect(() => {
    fetchRandomPosts();

    // Socket.ioの接続を設定
    socket.on('receiveMessage', (message) => {
      console.log('Received message:', message);
      // 必要に応じてメッセージを処理
    });

    // クリーンアップ: コンポーネントがアンマウントされるときにイベントリスナーを解除
    return () => {
      socket.off('receiveMessage');
    };
  }, []);

  useEffect(() => {
    fetch('/prefectures.json')
      .then(response => response.json())
      .then(data => setPrefectures(data))
      .catch(error => console.error('Error fetching prefectures:', error));
  }, []);

  const fetchRandomPosts = async () => {
    const response = await fetch('https://travel-class.com/api/posts/random');
    const data = await response.json();
    setPosts(data);
  };

  const fetchPosts = async (params = {}) => {
    const query = new URLSearchParams(params).toString();
    const response = await fetch(`https://travel-class.com/api/posts?${query}`);
    const data = await response.json();
    setPosts(data);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handleSearchClick = () => {
    fetchPosts({ search, date, location });
    setCurrentPage(1); // ページをリセット
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : 1));
  };

  return (
    <div className="home-container">
      <h2>Available Travelers</h2>
      <div className="filters">
        <input
          type="text"
          value={search}
          onChange={handleSearchChange}
          placeholder="Search posts..."
          className="search-field"
        />
        <input
          type="date"
          value={date}
          onChange={handleDateChange}
          className="filter-field"
        />
        <select
          value={location}
          onChange={handleLocationChange}
          className="filter-field"
        >
          <option value="">Select Location</option>
          {Object.entries(prefectures).map(([name, value]) => (
            <option key={value} value={value}>
              {name} ({value})
            </option>
          ))}
        </select>
        <button onClick={handleSearchClick} className="search-button">検索</button>
      </div>
      <div className="post-list">
        {currentPosts.map((post) => (
          <div key={post._id} className="post-card">
            <h3>{post.title}</h3>
            <p>{post.description}</p>
            <p><strong>Date:</strong> {post.date}</p>
            <p><strong>Location:</strong> {post.location}</p>
            <Link to={`/posts/${post._id}`} className="details-link">View Details</Link>
          </div>
        ))}
      </div>
      <div className="pagination">
        {currentPage > 1 && (
          <button onClick={handlePrevPage} className="pagination-button">Prev</button>
        )}
        {indexOfLastPost < posts.length && (
          <button onClick={handleNextPage} className="pagination-button">Next</button>
        )}
      </div>
      <div className="contact-info">
        <p>Contact us: <a href="mailto:travelclass0606@gmail.com">travelclass0606@gmail.com</a></p>
      </div>
    </div>
  );
};

export default Home;
